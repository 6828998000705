import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import CityPicker from './components/CityPicker';

// Function to update the hidden input field
const updateAdjacentHiddenInput = (pickerElement, selectedOption) => {
  // Find the closest hidden input adjacent to the picker element
  const hiddenInput = pickerElement.nextElementSibling;
  if (hiddenInput && hiddenInput.tagName === 'INPUT' && hiddenInput.type === 'hidden') {
    hiddenInput.value = selectedOption ? selectedOption.value : '';
  }
};

const appRoot  = document.getElementById('react-app-trip-planner');
if (appRoot) {
  const root = ReactDOM.createRoot(appRoot);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// Mount CityPicker components for WordPress use
document.querySelectorAll('.react-city-picker').forEach((pickerRoot) => {
  const root = ReactDOM.createRoot(pickerRoot);
  root.render(
    <React.StrictMode>
      <CityPicker
        onSelect={(selectedOption) => {
          updateAdjacentHiddenInput(pickerRoot, selectedOption);
        }}
        placeholder={window.cityPickerData?.default?.label || "Kies een station"} 
        defaultValue={window.cityPickerData?.default || null}
      />
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
